<template>
  <div>
    <template v-if="authorizationLoading">
      <v-container style="height: 100%;">
        <v-row align="center"
          style="height: 100%;">
            <Loading :message="$t('addAuthorization.loadingMessage')" />
        </v-row>
      </v-container>
    </template>
    <template v-else>
    <v-toolbar
      :height="$vuetify.breakpoint.smAndDown ? hasExternalDocumentationLink ? 112 : 56 : 64"
      flat :color="$vuetify.breakpoint.mdAndUp ? 'grey lighten-4' : ''">
      <v-container>
        <v-row no-gutters>
          <v-col cols="12" md="9" class="px-0 d-flex">
            <v-btn
              depressed
              rounded
              color="white"
              class="mr-2 mt-1 mr-2"
              style="color: #528ef7 !important"
              @click="onBack"
            >
              <v-icon size="24" color="primary" class="mr-2">navigate_before</v-icon>
              <span v-if="!$vuetify.breakpoint.smAndDown">{{ $t('addAuthorization.buttons.cancel') }}</span>
            </v-btn>
            <v-row no-gutters align="center">
              <v-col cols="12" md="auto">
                <span
                  :class="['secondary--text text-h3 mr-3', {'font-size-title-mobile mx-auto pr-16' : $vuetify.breakpoint.smAndDown}]"
                >
                  {{ $t('addAuthorization.title') }}
                </span>
              </v-col>
              <v-col cols="12" md="auto" v-if="currentCoverage && currentStep != 1">
                <span
                  :class="['secondary--text font-weight-bold body-2 mr-3', {'coverage-text-label' : $vuetify.breakpoint.smAndDown}]"
                >
                  ({{ coverageTypeLable }})
                </span>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-if="hasExternalDocumentationLink" cols="12" md="3" class="d-flex align-end flex-column">
            <v-btn
              depressed
              rounded
              color="osg-ambar-warning"
              dark
              :block='$vuetify.breakpoint.smAndDown && hasExternalDocumentationLink'
              class="mt-1 osg-black--text"
              @click="openLinkOnTab(sponsorConfigurations.externalDocumentationLink)"
            >
              <v-icon size="20" color="osg-black" class="mr-2">info</v-icon>
              <span>{{ $t('addAuthorization.buttons.btnLink') }}</span>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-toolbar>
     <AddAuthorizationContainer/>
    </template>
  </div>
</template>

<script>
import AddAuthorizationContainer from './AddAuthorizationContainer'
import Loading from '@/components/loading/Loading';
import { createNamespacedHelpers } from 'vuex'
const authorizationModule = createNamespacedHelpers('authorization');
export default {
  name: "AddAuthorizationLayout",
  components: {AddAuthorizationContainer, Loading},
  computed: {
    ...authorizationModule.mapGetters(['authorizationLoading','sponsorConfigurations','currentCoverage', 'currentStep']),
    hasExternalDocumentationLink() {
      return this.sponsorConfigurations.externalDocumentationLink != null;
    },
    coverageTypeLable() {
      return this.currentCoverage && this.currentCoverage.label || ''
    }
  },
  methods: {
    ...authorizationModule.mapActions(['resetAuthorizationState']),
    onBack() {
      this.resetAuthorizationState();
      this.$router.go(-1);
    },
    openLinkOnTab(link) {
      window.open(link, '_blank');
    }
  }
};
</script>
<style scoped>
.font-size-title-mobile {
  font-size: 1.125rem !important;
}
.coverage-text-label {
  font-size: 0.75rem !important;
}
</style>
